<template>
  <div class="table" v-show="tableData" id="infinite-list">
    <div class="table-wrapper" ref="iflist">
      <div class="table-row-header">
        <div class="search-input">
          <input
            type="text"
            placeholder="Search..."
            v-on:input="onSearch($event.target.value)"
          />
          <!-- Temp solution for including switch -->
        </div>
        <diyobo-switch
          :label="'Show Expected'"
          :value="guestlist"
          v-if="showExpected"
          @change="changeSwitch"
        />
      </div>

      <div class="table-row" v-for="(td, tdIndex) in filteredData" :key="td.id">
        <div class="inner-container">
          <div class="upper-cont">
            <div class="name">
              {{ td.name }}
            </div>

            <div class="total-edit">
              <div class="total" v-if="deniedTable">
                {{ td.exp_guests }}
              </div>

              <div
                v-else
                class="total"
                :class="[
                  { checkin: td.total_checkin > 0 },
                  { same: td.exp_guests == td.total_checkin },
                  { over: td.total_checkin > td.exp_guests }
                ]"
              >
                {{ td.total_checkin }}
              </div>

              <font-awesome-icon
                class="edit-icon"
                icon="chevron-right"
                @click="$emit('modal', td, tdIndex)"
              ></font-awesome-icon>
            </div>
          </div>
          <div class="bottom-cont" v-if="!deniedTable">
            <span class="spinner-input">
              <div class="male-counter">
                <div class="counter">
                  <font-awesome-icon
                    class="prefix-icon"
                    icon="mars"
                  ></font-awesome-icon>
                  <span class="minus" @click="minus('male_person_checkin', td)">
                    -
                  </span>
                  <input
                    type="number"
                    name=""
                    id=""
                    class="male-input"
                    min="1"
                    disabled
                    v-model="td.male_person_checkin"
                  />
                  <span class="plus" @click="plus('male_person_checkin', td)">
                    +
                  </span>
                </div>
              </div>
            </span>
            <span class="spinner-input">
              <div class="male-counter">
                <div class="counter">
                  <font-awesome-icon
                    class="prefix-icon"
                    icon="venus"
                  ></font-awesome-icon>
                  <span
                    class="minus"
                    @click="minus('female_person_checkin', td)"
                  >
                    -
                  </span>
                  <input
                    type="number"
                    name=""
                    id=""
                    disabled
                    class="male-input"
                    min="1"
                    v-model="td.female_person_checkin"
                  />
                  <span class="plus" @click="plus('female_person_checkin', td)">
                    +
                  </span>
                </div>
              </div>
            </span>
            <span class="spinner-input">
              <div class="male-counter">
                <div class="counter">
                  <font-awesome-icon
                    class="prefix-icon"
                    icon="genderless"
                  ></font-awesome-icon>
                  <span
                    class="minus"
                    @click="minus('non_specific_person_checkin', td)"
                  >
                    -
                  </span>
                  <input
                    type="number"
                    name=""
                    id=""
                    disabled
                    class="male-input"
                    min="1"
                    v-model="td.non_specific_person_checkin"
                  />
                  <span
                    class="plus"
                    @click="plus('non_specific_person_checkin', td)"
                  >
                    +
                  </span>
                </div>
              </div>
            </span>
          </div>
          <div class="exp-cont" v-if="guestlist || deniedTable">
            <span class="exp">
              <span class="number" :class="{ deniedList: deniedTable }">
                <font-awesome-icon
                  v-if="deniedTable"
                  class="prefix-icon"
                  icon="mars"
                ></font-awesome-icon>
                {{ td.male_person }}
              </span>
            </span>
            <span class="exp">
              <span class="number" :class="{ deniedList: deniedTable }">
                <font-awesome-icon
                  v-if="deniedTable"
                  class="prefix-icon"
                  icon="venus"
                ></font-awesome-icon>
                {{ td.female_person }}
              </span>
            </span>
            <span class="exp">
              <span class="number" :class="{ deniedList: deniedTable }">
                <font-awesome-icon
                  v-if="deniedTable"
                  class="prefix-icon"
                  icon="genderless"
                ></font-awesome-icon>
                {{ td.non_specific_person }}
              </span>
            </span>
          </div>

          <div class="extra-cont">
            <div class="items">
              <v-icon
                :dark="true"
                class="item-icon"
                :class="{
                  active: isActive(td, 'foods')
                }"
              >
                mdi-silverware-fork-knife
              </v-icon>

              <v-icon
                :dark="true"
                class="item-icon"
                :class="{
                  active: isActive(td, 'bottles')
                }"
              >
                mdi-bottle-wine
              </v-icon>

              <v-icon
                :dark="true"
                class="item-icon"
                :class="{
                  active: isActive(td, 'sections')
                }"
              >
                mdi-sofa-single
              </v-icon>
            </div>
            <FormulateInput
              placeholder="Select"
              :options="statusOptions"
              type="select"
              name="methodType"
              validation="required"
              v-model="td.status"
              @input="statusChanged($event, td)"
            />
          </div>
          <div class="bottom-cont"></div>
        </div>
      </div>
      <div class="loading" v-if="loading">loading...</div>
    </div>
    <div class="table-footer" v-if="!infiniteScroll">
      <div class="entries-wrapper" v-if="tableData.length > 0">
        <p>
          Showing {{ showingFrom }} to {{ showingTo }} of
          {{ filteredData.length }}
          <span v-if="tableData.length === 1">order</span>
          <span v-else>orders</span>
          .
        </p>
      </div>
      <div class="pagination" v-if="pageButtons.length > 1">
        <p
          class="from"
          :class="{ inactive: page <= 1 }"
          v-on:click="paginate(-1, true)"
        >
          <font-awesome-icon icon="chevron-left" />
        </p>
        <p
          v-for="_page in pageButtons"
          :key="_page"
          :class="{ active: _page === page }"
          v-on:click="paginate(_page)"
        >
          {{ _page }}
        </p>
        <p
          class="to"
          :class="{ inactive: page >= pages }"
          v-on:click="paginate(1, true)"
        >
          <font-awesome-icon icon="chevron-right" />
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import DiyoboCalendar from "@/components/diyoboCalendar.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import debounce from "debounce";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCheck,
  faTimes,
  faFile,
  faReceipt,
  faExternalLinkAlt,
  faTransgender,
  faMars,
  faVenus,
  faGenderless
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { downloadTableToteAsCSV } from "@/helpers/csv";
import formatMoney from "@/helpers/money";
import {
  emailCheck,
  phoneCheck,
  formatPhoneNumber
} from "@/helpers/input-formats";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";
import DiyoboSwitch from "@/components/DiyoboSwitch.vue";

const CURRENT = "current";
const DENIED = "denied";
const WAITING = "waiting";

library.add(
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCheck,
  faTimes,
  faFile,
  faReceipt,
  faExternalLinkAlt,
  faMars,
  faVenus,
  faGenderless
);

export default {
  name: "mobile-tote",
  components: {
    DiyoboInput,
    DiyoboButton,
    FontAwesomeIcon,
    ProcessingModal,
    DiyoboCalendar,
    DiyoboSwitch
  },
  props: {
    type: { type: String, default: CURRENT },
    deniedTable: { type: Boolean, default: false },
    guestlist: { type: Boolean, default: false },
    showExpected: { type: Boolean, default: false },
    value: Object,
    dateFrom: String,
    infiniteScroll: { type: Boolean, default: true },
    dateTo: String,
    dateSearch: {
      type: Boolean,
      default: () => false
    },
    searchAble: {
      default: () => []
    },
    tableData: {
      default: () => []
    }
  },
  data() {
    return {
      tableSizes: [
        { label: "10", value: 10 },
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
      ],
      tableSize: { label: "10", value: 10 },
      page: 1,
      search: "",
      sortColumn: null,
      sortDirection: "ascending",
      processing: false,
      loading: false,
      start: "",
      end: "",
      statusOptions: [
        { label: "Approved", value: "approved" },
        { label: "Denied", value: "denied" },
        { label: "Pending", value: "pending" }
      ]
    };
  },
  computed: {
    // columns() {
    //   return this.value.columns.filter((c) => !c.hidden);
    // },
    pages() {
      if (!this.tableData) return 0;
      return Math.ceil(this.tableData.length / this.tableSize.value);
    },
    // datepickerIdSuffix() {
    //   return this.value.title ? this.value.title.replace(/ /g, "") : "";
    // },
    pageButtons() {
      if (this.pages <= 4) {
        return [1, 2, 3, 4].slice(0, this.pages);
      }

      if (this.page > this.pages - 5) {
        return [
          this.pages - 4,
          this.pages - 3,
          this.pages - 2,
          this.pages - 1,
          this.pages
        ];
      }

      return [this.page, this.page + 1, "...", this.pages - 1, this.pages];
    },
    filteredData() {
      if (this.search == "") {
        if (this.start == "" || this.end == "") {
          return this.tableData;
        } else {
          let d1 = new Date(this.start).getTime();
          let d2 = new Date(this.end).getTime();

          return this.tableData.filter(
            e =>
              new Date(e.date).getTime() >= d1 &&
              new Date(e.date).getTime() <= d2
          );
        }
      } else {
        if (this.start == "" || this.end == "") {
          return this.tableData.filter(e =>
            this.searchAble.some(s => e[s].toLowerCase().includes(this.search))
          );
        } else {
          let d1 = new Date(this.start).getTime();
          let d2 = new Date(this.end).getTime();

          return this.tableData.filter(
            e =>
              new Date(e.date).getTime() >= d1 &&
              new Date(e.date).getTime() <= d2 &&
              this.searchAble.some(s =>
                e[s].toLowerCase().includes(this.search)
              )
          );
        }
      }
    },
    displayData() {
      const offset = (this.page - 1) * this.tableSize.value;

      return this.filteredData.slice(offset, offset + this.tableSize.value);
    },
    showingFrom() {
      return (this.page - 1) * this.tableSize.value + 1;
    },
    showingTo() {
      return Math.min(
        this.page * this.tableSize.value,
        this.filteredData.length
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      // const listElm = document.querySelector("#infinite-list");
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.scrollHeight;

        if (bottomOfWindow) {
          this.$emit("loadNext", this.page + 1, this.type);
        }
      };
    });
  },
  methods: {
    statusChanged(status, item) {
      this.$emit("statusChanged", status, item);
    },
    isActive(item, type) {
      let response = null;

      if (item.guestlist_items) {
        if (item.guestlist_items.some(i => i.type === "packages")) {
          let groupItems = item.guestlist_items.filter(
            i => i.type === "packages"
          );

          groupItems.forEach(gi => {
            response = gi[`added_${type}`].length > 0;
          });
          return response;
        }
        if (item.guestlist_items.some(i => i.type === type)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    incrementPage() {
      this.page++;
    },
    onSearch(search) {
      this.search = search.toLowerCase();
      if (this.page > 1) this.page = 1;
    },
    minus(type, list) {
      this.$emit("minus", list, type);
    },
    plus(type, list) {
      this.$emit("plus", list, type);
    },
    paginate(page, relative) {
      if (page === "...") return;
      if (relative) {
        if (page === -1 && this.page > 1) this.page += page;
        else if (page === 1 && this.page < this.pages) this.page += page;
      } else this.page = page;
    },
    changeSwitch(val) {
      this.$emit("change", val);
    }
  }
};
</script>

<style lang="less">
.table {
  .input-wrapper {
    .dropdown {
      padding: 4px !important;

      .icon-down {
        right: 7px;
        top: 6px;
      }
    }
    .option {
      padding: 4px !important;
    }
  }
}
</style>
<style lang="less" scoped>
.item-icon.active {
  color: var(--primary);
}
.table {
  .table-wrapper {
    position: relative;
    border-radius: 8px;
    box-shadow: var(--table-shadow);
    width: 100%;
    overflow: auto;
    min-height: 180px;
    padding-bottom: 110px;
    border: 1px solid #2f3339;
    .table-row-header {
      margin: 9px;
      display: flex;
      justify-content: space-between;
      .search-input {
        width: 50%;
        input[type="text"] {
          display: block;
          padding: 12px 0px;
          background: var(--input-background);
          text-indent: 14px;
          width: 100%;
          height: 24px;
          margin-top: 10px;

          font-family: inherit;
          font-size: 1em;
          color: #888;
          border: 1px solid transparent;
          border-radius: 5px;
          box-shadow: 1px 1px 2px -1px #ffffff26,
            inset 1px 1px 3px 0px #00000075;
          outline: none;
          -webkit-appearance: none;
          transition: all 0.4s ease, visibility 0s;

          &:focus {
            // width: 188px;
            color: var(--text);
            border: 1px solid #48bb8b;
            text-indent: 32px;
            background: var(--active-background);
          }
        }
      }

      .switch-wrapper {
        margin: 0;
      }
    }
    .table-row {
      background-color: var(--table-bg);
      box-shadow: var(--table-shadow);
      margin: 9px;
      position: relative;
      padding: 16px 6px;
      border-bottom: 1px solid #2f3339;
      border-radius: inherit;
      .inner-container {
        .upper-cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          padding: 0 6px;

          .total-edit {
            display: flex;
            align-items: center;
            color: grey;

            .edit-icon {
              cursor: pointer;
            }
          }
          .total {
            color: white;
            margin-right: 20px;
            background: grey;
            padding: 2px 20px;
            border-radius: 20px;

            &.checkin {
              background: #449a4c;
            }
            &.same {
              background: #2bacd9;
            }
            &.over {
              background: red;
            }
          }
        }

        .bottom-cont {
          display: flex;
          margin-bottom: 10px;
          span.spinner-input {
            width: 100%;

            div.counter {
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                color: var(--primary-green);
              }
              .minus,
              .plus {
                font-size: 12px;
                align-items: center;
                background: var(--dropdown-background);
                color: var(--text);
                margin: 0 5px;
                width: 15px;
                height: 15px;
                display: flex;
                justify-content: center;
                border-radius: 14px;
                border: 1px solid var(--dropdown-border);
                font-weight: bold;
                cursor: pointer;
                user-select: none;
              }
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
              }

              input[type="number"] {
                -moz-appearance: textfield; /* Firefox */
              }
              input {
                width: 30px;
                text-align: center;
                box-shadow: 1px 1px 2px -1px #ffffff26,
                  inset 1px 1px 3px 0px #00000026;
                border: 1px solid transparent;
                background: var(--input-background);
                color: var(--text);
                border-radius: 4px;
                display: inline-block;
                vertical-align: middle;
                -webkit-appearance: none;
                outline: none;

                &:hover {
                  border: 1px solid var(--input-border);
                }
                &:focus {
                  color: var(--text);
                  border: 1px solid #48bb8b;
                  background-color: var(--active-background);
                }
              }
            }
          }
        }

        .exp-cont {
          display: flex;
          .exp {
            text-align: center;
            width: 100%;

            .number {
              font-size: 14px;
              margin-left: 8px;
              svg {
                color: var(--primary-green);
              }

              &.deniedList {
                font-size: 20px;
              }
            }
          }
        }

        .extra-cont {
          display: flex;
          padding: 16px;
          justify-content: space-between;

          .formulate-input {
            margin-bottom: initial;
            width: 50%;
          }
        }
      }
      &.header {
        display: flex;
      }
      &.deniedlist {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr 2fr;

        @media screen and (max-width: 890px) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr 2fr;
        }
      }

      &:not(.header, .sub-header) {
        // overflow: auto;
        min-width: 1000px;
      }
      @media screen and (max-width: 890px) {
        &:not(.header, .sub-header) {
          min-width: initial;
        }
      }

      .modal-btn {
        cursor: pointer;
      }
    }
  }
  .table-footer {
    position: relative;
    height: 50px;
    margin: 10px 0 40px;

    .entries-wrapper {
      position: absolute;
      left: 2px;
    }

    .pagination {
      top: -7px;

      position: absolute;
      display: flex;
      flex-wrap: wrap;
      right: 1px;
      user-select: none;

      p {
        width: 8px;
        padding: 7px 14px;
        border: rgba(91, 91, 91, 0.3) solid 1px;
        // color: #4eb95e;

        &:hover {
          cursor: pointer;
          background-color: rgba(91, 91, 91, 0.3);
        }

        &.active {
          background-color: rgba(91, 91, 91, 0.4);
        }

        &.inactive {
          color: #999999;
          pointer-events: none;
        }

        &.from {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &.to {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
</style>
