<template>
  <div class="view-list-container">
    <!-- <guest-info-modal
      v-if="guestPopupActive"
      :info="guestPopup"
      :settings="guestlistSettings"
      @save-guest="changeGuest"
      v-on:close="closeGuestPopup"
    /> -->

    <guest-info-modal-2 ref="detailsModal" />
    <deny-reason-modal
      ref="denyModal"
      @save="denyGuest"
      v-on:close="closeDenyPopup"
    />
    <alert-modal ref="alert" @saveInline="saveInlineChanges" />

    <h2>View List</h2>

    <div class="stats-cont">
      <div class="total-expected">
        <b><label for="">Total Expected:</label></b>
        {{ getTotalExp }}
      </div>
      <div class="total-expected">
        <b><label for="">Total Checked-In:</label></b>
        {{ getTotalCheck }}
      </div>
      <div class="total-expected">
        <b><label for="">Difference:</label></b>
        {{ getDiff }}
      </div>
    </div>
    <div class="row">
      <div class="col full" v-if="mobile">
        <diyobo-input
          type="dropdown"
          label=""
          placeholder=""
          :options="listDropdown"
          v-model="activeList"
          :val="activeList"
        />
      </div>
    </div>
    <div class="row">
      <div class="col full" v-if="data">
        <DataTable
          v-if="!mobile"
          :dense="false"
          title="Current Guest List"
          endpoint="guestlist/get-guests/:event"
          :args="{ event: url }"
          ref="current-guest-list"
          :headers="this.tableData1.columns"
          :expand="tableData1.showExpected"
          searchPlaceholder="Name or Email Search"
          :csvMapper="mapCSVData"
          :custom-columns="[
            'male_person_checkin',
            'female_person_checkin',
            'non_specific_person_checkin',
            'total_checkin',
            'edit',
            'status',
            'name'
          ]"
        >
          <template #header>
            <diyobo-switch
              :label="'Show Expected'"
              :value="tableData1.showExpected"
              @change="changeSwitch"
            />
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="name-tag">
              <div class="red-dot" @click="editGuestTag(item)"></div>
              <div class="name">{{ item.name }}</div>
            </div>
          </template>
          <template v-slot:[`item.male_person_checkin`]="{ item }">
            <span class="spinner-input">
              <div class="male-counter">
                <div class="counter">
                  <span
                    class="minus"
                    @click="
                      minus(
                        'male_person_checkin',
                        item,
                        item.male_person_checkin
                      )
                    "
                  >
                    -
                  </span>
                  <input
                    type="number"
                    name=""
                    id=""
                    class="male-input"
                    min="1"
                    disabled
                    v-model="item.male_person_checkin"
                  />
                  <span
                    class="plus"
                    @click="
                      plus(
                        'male_person_checkin',
                        item,
                        item.male_person_checkin
                      )
                    "
                  >
                    +
                  </span>
                </div>
              </div>
            </span>
          </template>
          <template v-slot:[`item.female_person_checkin`]="{ item }">
            <span class="spinner-input">
              <div class="male-counter">
                <div class="counter">
                  <span
                    class="minus"
                    @click="
                      minus(
                        'female_person_checkin',
                        item,
                        item.female_person_checkin
                      )
                    "
                  >
                    -
                  </span>
                  <input
                    type="number"
                    name=""
                    id=""
                    disabled
                    class="male-input"
                    min="1"
                    v-model="item.female_person_checkin"
                  />
                  <span
                    class="plus"
                    @click="
                      plus(
                        'female_person_checkin',
                        item,
                        item.female_person_checkin
                      )
                    "
                  >
                    +
                  </span>
                </div>
              </div>
            </span>
          </template>
          <template v-slot:[`item.non_specific_person_checkin`]="{ item }">
            <span class="spinner-input">
              <div class="male-counter">
                <div class="counter">
                  <span
                    class="minus"
                    @click="
                      minus(
                        'non_specific_person_checkin',
                        item,
                        item.non_specific_person_checkin
                      )
                    "
                  >
                    -
                  </span>
                  <input
                    type="number"
                    name=""
                    id=""
                    disabled
                    class="male-input"
                    min="1"
                    v-model="item.non_specific_person_checkin"
                  />
                  <span
                    class="plus"
                    @click="
                      plus(
                        'non_specific_person_checkin',
                        item,
                        item.non_specific_person_checkin
                      )
                    "
                  >
                    +
                  </span>
                </div>
              </div>
            </span>
          </template>
          <template v-slot:[`item.total_checkin`]="{ item }">
            <span
              class="guest-total"
              :class="[
                { checkin: item.total_checkin > 0 },
                { same: item['exp_guests'] == item.total_checkin },
                { over: item.total_checkin > item['exp_guests'] }
              ]"
            >
              {{ item.total_checkin }}
            </span>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <td style="column-gap: 3px;display: flex;align-items: center;">
              <v-icon @click="editGuest(item)">mdi-pencil</v-icon>
              <v-icon
                class="item-icon"
                :class="{
                  active: isActive(item, 'foods')
                }"
              >
                mdi-silverware-fork-knife
              </v-icon>

              <v-icon
                class="item-icon"
                :class="{
                  active: isActive(item, 'bottles')
                }"
              >
                mdi-bottle-wine
              </v-icon>

              <v-icon
                class="item-icon"
                :class="{
                  active: isActive(item, 'sections')
                }"
              >
                mdi-sofa-single
              </v-icon>
            </td>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <!-- {{ tableData1.columns[6].options }} -->

            <FormulateInput
              placeholder="Select"
              :options="tableData1.columns[6].options"
              type="select"
              name="methodType"
              validation="required"
              v-model="item.status"
              @input="statusChanged($event, item)"
            />
          </template>

          <template v-slot:expanded-item="{ item }">
            <td></td>
            <td class="text-center">{{ item.male_person }}</td>
            <td class="text-center">{{ item.female_person }}</td>
            <td class="text-center">{{ item.non_specific_person }}</td>
            <td class="text-center">
              <span class="guest-total">
                {{ item.exp_guests }}
              </span>
            </td>

            <!-- <td class="tags">
              <a @click="editGuestTag(item)">Tags</a>
            </td> -->
            <td v-if="!mobile"></td>
            <td v-if="!mobile"></td>
          </template>
        </DataTable>

        <mobile-table
          v-if="mobile && currentList === 'current-list'"
          type="current"
          :tableData="data"
          ref="mobileTable"
          @minus="minus"
          @plus="plus"
          @modal="editGuest"
          :guestlist="expectedGuestlist"
          :showExpected="true"
          @change="changeExpected"
          :searchAble="['name', 'email']"
          @loadNext="getMobCurrentList"
          @statusChanged="statusChanged"
        />
      </div>
    </div>

    <div class="row">
      <div class="col full">
        <DataTable
          v-if="!mobile"
          :dense="false"
          title="Denied Guest List"
          endpoint="guestlist/get-guests/:event"
          :args="{ event: url }"
          :params="{ type: 'denied' }"
          ref="current-denied-list"
          :headers="this.tableData2.columns"
          :expand="tableData2.showExpected"
          searchPlaceholder="Name or Email Search"
          :custom-columns="[
            'male_person_checkin',
            'female_person_checkin',
            'non_specific_person_checkin',
            'total_checkin',
            'edit',
            'status',
            'change'
          ]"
        >
          <template #header>
            <!--            <diyobo-switch-->
            <!--              :label="'Show Expected'"-->
            <!--              :value="tableData2.showExpected"-->
            <!--              @change="changeSwitch"-->
            <!--            />-->
          </template>
          <template v-slot:[`item.male_person_checkin`]="{ item }">
            <span>{{ item.male_person_checkin }}</span>
          </template>
          <template v-slot:[`item.female_person_checkin`]="{ item }">
            <span>{{ item.female_person_checkin }}</span>
          </template>
          <template v-slot:[`item.non_specific_person_checkin`]="{ item }">
            <span>{{ item.non_specific_person_checkin }}</span>
          </template>
          <template v-slot:[`item.total_checkin`]="{ item }">
            <span>
              {{ item.total_checkin }}
            </span>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-icon @click="editGuest(item)">mdi-pencil</v-icon>
            <!-- <v-icon>mdi-silverware-fork-knife</v-icon>

            <v-icon>mdi-bottle-wine</v-icon>

            <v-icon>mdi-sofa-single</v-icon> -->
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <FormulateInput
              placeholder="Select"
              :options="tableData2.columns[6].options"
              type="select"
              name="methodType"
              validation="required"
              @input="statusChanged($event, item)"
            />
          </template>
          <!-- <template v-slot:[`item.change`]="{ item }">
            <v-icon @click="editGuest(item)">mdi-content-save</v-icon>
            <v-icon @click="editGuest(item)">mdi-close-circle</v-icon>
          </template> -->
        </DataTable>

        <mobile-table
          v-if="mobile && currentList === 'denied-list'"
          :tableData="deniedData"
          type="denied"
          :deniedTable="true"
          @minus="minus"
          @plus="plus"
          @modal="editGuest"
          @change="changeExpected"
          :searchAble="['name', 'email']"
          @loadNext="getMobCurrentList"
          @statusChanged="statusChanged"
        />

        <!-- <table-tote
          v-if="!mobile"
          v-model="deniedTableData"
          @modal="editGuest"
          :deniedTable="true"
          @save="saveConfirmDenied"
        /> -->
      </div>
    </div>

    <div class="row">
      <div class="col full">
        <DataTable
          v-if="!mobile"
          :dense="false"
          title="Waitlist"
          endpoint="guestlist/get-guests/:event"
          :args="{ event: url }"
          :params="{ type: 'waiting' }"
          ref="wait-guest-list"
          :headers="this.waitlistData.columns"
          :expand="waitlistData.showExpected"
          searchPlaceholder="Name or Email Search"
          :custom-columns="[
            'male_person',
            'female_person',
            'non_specific_person',
            'total_checkin',
            'edit',
            'status',
            'change'
          ]"
        >
          <template v-slot:[`item.male_person`]="{ item }">
            <span>{{ item.male_person }}</span>
          </template>
          <template v-slot:[`item.female_person`]="{ item }">
            <span>{{ item.female_person }}</span>
          </template>
          <template v-slot:[`item.non_specific_person`]="{ item }">
            <span>{{ item.non_specific_person }}</span>
          </template>
          <template v-slot:[`item.total_checkin`]="{ item }">
            <span
              class="guest-total"
              :class="[
                { checkin: item.total_checkin > 0 },
                { same: item['exp_guests'] == item.total_checkin },
                { over: item.total_checkin > item['exp_guests'] }
              ]"
            >
              {{ item.total_checkin }}
            </span>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-icon @click="editGuest(item)">mdi-pencil</v-icon>
            <v-icon
              class="item-icon"
              :class="{
                active: isActive(item, 'foods')
              }"
            >
              mdi-silverware-fork-knife
            </v-icon>

            <v-icon
              class="item-icon"
              :class="{
                active: isActive(item, 'bottles')
              }"
            >
              mdi-bottle-wine
            </v-icon>

            <v-icon
              class="item-icon"
              :class="{
                active: isActive(item, 'sections')
              }"
            >
              mdi-sofa-single
            </v-icon>
          </template>
          <template v-slot:[`item.status`]="{item}">
            <FormulateInput
              placeholder="Select"
              :options="tableData1.columns[6].options"
              type="select"
              name="methodType"
              validation="required"
              @input="statusChanged($event, item)"
            />
          </template>
          <!-- <template v-slot:[`item.change`]="{ item }">
            <v-icon @click="editGuest(item)">mdi-content-save</v-icon>
            <v-icon @click="editGuest(item)">mdi-close-circle</v-icon>
          </template> -->
        </DataTable>
        <mobile-table
          v-if="mobile && currentList === 'waiting-list'"
          :tableData="waitingData"
          type="waiting"
          :deniedTable="true"
          @minus="minus"
          @plus="plus"
          @modal="editGuest"
          @change="changeExpected"
          :searchAble="['name', 'email']"
          @loadNext="getMobCurrentList"
          @statusChanged="statusChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { formatPhoneNumber } from "@/helpers/input-formats.js";
import TableTote from "../../components/guestlist/Table.vue";
import MobileTable from "../../components/guestlist/MobileTable.vue";
import DataTable from "@/components/DataTable.vue";

import GuestInfoModal from "../../components/guestlist/GuestInfoModal.vue";
import DenyReasonModal from "../../components/guestlist/DenyReasonModal.vue";
import ModalContent from "@/helpers/modals";
import AlertModal from "@/components/modals/AlertModal.vue";
import DiyoboSwitch from "@/components/DiyoboSwitch.vue";
import GuestInfoModal2 from "../../components/guestlist/GuestInfoModal2.vue";

export default {
  components: {
    AlertModal,
    DiyoboInput,
    DiyoboButton,
    TableTote,
    GuestInfoModal,
    DenyReasonModal,
    MobileTable,
    DataTable,
    DiyoboSwitch,
    GuestInfoModal2
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "View List"
    };
  },
  data() {
    return {
      itemStatusChanged: null,
      ignoreStatusChange: false,
      totalExp: "",
      totalCheckIn: "",
      mobile: false,
      activeList: "current-list",
      listDropdown: [
        {
          label: "Current List",
          value: "current-list"
        },
        {
          label: "Denied List",
          value: "denied-list"
        },
        {
          label: "Waiting List",
          value: "waiting-list"
        }
      ],
      showExpected: true,
      tableData1: {
        title: "Current Guest List",
        columns: [
          {
            text: "Full Name",
            value: "name",

            row2: true,

            center: true,
            centerTitle: true
          },

          {
            text: "Male",
            value: "male_person_checkin",

            spinner: true,
            centerTitle: true,
            prefixIcon: "mars",
            male: true,
            row2: true
          },
          {
            text: "Female",
            value: "female_person_checkin",

            spinner: true,
            centerTitle: true,
            row2: true,
            prefixIcon: "venus",
            female: true
          },
          {
            text: "NS",
            value: "non_specific_person_checkin",

            spinner: true,
            centerTitle: true,
            prefixIcon: "genderless",
            ns: true,
            row2: true
          },
          {
            text: "Total",
            value: "total_checkin",
            guesttotal: true,
            centerTitle: true,
            row2: true
          },
          {
            text: "Details",
            value: "edit",

            modal: true,
            modalTitle: "Edit",
            row2: true,
            center: true,
            centerTitle: true
          },
          {
            text: "Approval",
            value: "status",
            options: [
              { label: "Approved", value: "approved" },
              { label: "Denied", value: "denied" },
              { label: "Pending", value: "pending" }
            ]
          },
          {
            text: "",
            value: "change"
          }
          // { title: "Expected Male", field: "male_person", hidden: true },
          // {
          //   title: "Expected Female",
          //   field: "female_person",
          //   hidden: true
          // },
          // {
          //   title: "Expected NS",
          //   field: "non_specific_person",
          //   hidden: true
          // },
          // {
          //   title: "Additional Notes",
          //   field: "additional_data",
          //   hidden: true
          // },

          // { title: "Submitted By", field: "submitted_by", hidden: true },
          // { title: "Phone", field: "phone", hidden: true },
          // { title: "Email", field: "email", hidden: true },

          // {
          //   title: "",
          //   field: "checkedIn",
          //   hidden: true
          // },
          // {
          //   title: "",
          //   field: "status",
          //   hidden: true
          // },

          // {
          //   title: "Approval",
          //   field: "status",
          //   width: "12%",
          //   centerTitle: true,
          //   center: true,
          //   row2: true,
          //   dropdown: true,
          //   status: true,

          //   options: [
          //     { label: "Approved", value: "approved" },
          //     { label: "Denied", value: "denied" },
          //     { label: "Pending", value: "pending" }
          //   ]
          // },
          // {
          //   title: "",
          //   field: "change",
          //   width: "5%",
          //   centerTitle: true,
          //   row2: true,
          //   checkBtn: true
          //   // guestlistBtn: true
          // }
        ],
        showExpected: false
      },
      tableData2: {
        title: "Denied Guest List",
        columns: [
          {
            text: "Full Name",
            value: "name",

            row2: true,

            center: true,
            centerTitle: true
          },

          {
            text: "Male",
            value: "male_person_checkin",

            spinner: true,
            centerTitle: true,
            prefixIcon: "mars",
            male: true,
            row2: true
          },
          {
            text: "Female",
            value: "female_person_checkin",

            spinner: true,
            centerTitle: true,
            row2: true,
            prefixIcon: "venus",
            female: true
          },
          {
            text: "NS",
            value: "non_specific_person_checkin",

            spinner: true,
            centerTitle: true,
            prefixIcon: "genderless",
            ns: true,
            row2: true
          },
          {
            text: "Total",
            value: "total_checkin",

            guesttotal: true,
            centerTitle: true,
            row2: true
          },
          {
            text: "Details",
            value: "edit",

            modal: true,
            modalTitle: "Edit",
            row2: true,
            center: true,
            centerTitle: true
          },
          {
            text: "Approval",
            value: "status",
            options: [
              { label: "Approved", value: "approved" },
              { label: "Waiting", value: "waiting" }
            ]
          },
          {
            text: "",
            value: "change"
          }
        ],
        showExpected: false
      },
      waitlistData: {
        title: "Waitlist",
        columns: [
          {
            text: "Full Name",
            value: "name",

            row2: true,

            center: true,
            centerTitle: true
          },

          {
            text: "Male",
            value: "male_person",

            spinner: true,
            centerTitle: true,
            prefixIcon: "mars",
            male: true,
            row2: true
          },
          {
            text: "Female",
            value: "female_person",

            spinner: true,
            centerTitle: true,
            row2: true,
            prefixIcon: "venus",
            female: true
          },
          {
            text: "NS",
            value: "non_specific_person",

            spinner: true,
            centerTitle: true,
            prefixIcon: "genderless",
            ns: true,
            row2: true
          },
          {
            text: "Total",
            value: "total_checkin",

            guesttotal: true,
            centerTitle: true,
            row2: true
          },
          {
            text: "Details",
            value: "edit",

            modal: true,
            modalTitle: "Edit",
            row2: true,
            center: true,
            centerTitle: true
          },
          {
            text: "Approval",
            value: "status",
            options: [
              { label: "Approved", value: "approved" },
              { label: "Denied", value: "denied" },
              { label: "Pending", value: "pending" }
            ]
          },
          {
            text: "",
            value: "change"
          }
        ],
        showExpected: false
      },
      tablesize: 20,
      expectedGuestlist: true,
      deniedTableData: {},
      deniedData: [],
      data: {},
      copyData: {},
      guestPopup: {},
      tempList: "",
      guestPopupActive: false,
      denyReason: "",
      denyPopupActive: false,
      denyModal: {},
      guestlistSettings: {}
    };
  },
  computed: {
    currentList() {
      return this.activeList;
    },
    currentTable() {
      if (this.expectedGuestlist) {
        return this.tableData2;
      } else {
        return this.tableData1;
      }
    },
    getTotalExp() {
      return this.totalExp;
    },
    getTotalCheck() {
      return this.totalCheckIn;
    },
    getDiff() {
      return this.totalExp - this.totalCheckIn;
    },
    mobileTable() {
      if (window.innerWidth < 450) {
        return true;
      } else {
        return false;
      }
    },
    embedLink() {
      let currentEnv = process.env.VUE_APP_ENVIRONMENT;

      if (currentEnv === "staging") {
        return "https://staging.incredevent.com";
      } else if (currentEnv === "production") {
        return "https://incredevent.com";
      } else {
        return "http://localhost:3100";
      }
    }
  },
  async mounted() {
    if (window.innerWidth < 650) {
      this.mobile = true;
    } else if (window.innerWidth < 900) {
      this.tableData1.columns = [
        {
          text: "Full Name",
          value: "name",

          row2: true,

          center: true,
          centerTitle: true
        },

        {
          text: "Male",
          value: "male_person_checkin",

          spinner: true,
          centerTitle: true,
          prefixIcon: "mars",
          male: true,
          row2: true
        },
        {
          text: "Female",
          value: "female_person_checkin",

          spinner: true,
          centerTitle: true,
          row2: true,
          prefixIcon: "venus",
          female: true
        },
        {
          text: "NS",
          value: "non_specific_person_checkin",

          spinner: true,
          centerTitle: true,
          prefixIcon: "genderless",
          ns: true,
          row2: true
        },
        {
          text: "Total",
          value: "total_checkin",

          guesttotal: true,
          centerTitle: true,
          row2: true
        },
        {
          text: "Details",
          value: "edit",

          modal: true,
          modalTitle: "Edit",
          row2: true,
          center: true,
          centerTitle: true
        },
        {
          text: "Approval",
          value: "status",
          options: [
            { label: "Approved", value: "approved" },
            { label: "Denied", value: "denied" },
            { label: "Pending", value: "pending" }
          ]
        }
        // {
        //   text: "",
        //   value: "change"
        // }
      ];
    } else {
      this.mobile = false;
      this.tableData1.columns = [
        {
          text: "Full Name",
          value: "name",

          row2: true,

          center: true,
          centerTitle: true
        },

        {
          text: "Male",
          value: "male_person_checkin",

          spinner: true,
          centerTitle: true,
          prefixIcon: "mars",
          male: true,
          row2: true
        },
        {
          text: "Female",
          value: "female_person_checkin",

          spinner: true,
          centerTitle: true,
          row2: true,
          prefixIcon: "venus",
          female: true
        },
        {
          text: "NS",
          value: "non_specific_person_checkin",

          spinner: true,
          centerTitle: true,
          prefixIcon: "genderless",
          ns: true,
          row2: true
        },
        {
          text: "Total",
          value: "total_checkin",

          guesttotal: true,
          centerTitle: true,
          row2: true
        },
        {
          text: "Details",
          value: "edit",

          modal: true,
          modalTitle: "Edit",
          row2: true,
          center: true,
          centerTitle: true
        },
        {
          text: "Approval",
          value: "status",
          options: [
            { label: "Approved", value: "approved" },
            { label: "Denied", value: "denied" },
            { label: "Pending", value: "pending" }
          ]
        },
        {
          text: "",
          value: "change"
        }
      ];
    }
    window.addEventListener("resize", this.onResize);

    // await this.getGuestSettings();
    await this.getListStats();
    await this.getCurrentList();
    await this.getDeniedList();
    await this.getWaitingList();
  },
  methods: {
    isActive(item, type) {
      let response = null;

      if (item.guestlist_items) {
        if (item.guestlist_items.some(i => i.type === "packages")) {
          let groupItems = item.guestlist_items.filter(
            i => i.type === "packages"
          );

          groupItems.forEach(gi => {
            response = gi[`added_${type}`].length > 0;
          });
          return response;
        }
        if (item.guestlist_items.some(i => i.type === type)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    openDenyModal(item) {
      this.$refs.denyModal.open(item.deny_reason);
    },
    statusChanged(status, item) {
      if(item.old_status == status) {
        return;
      }
      if (status == "denied") {
        if(this.ignoreStatusChange) {
          this.ignoreStatusChange = false;
          return;
        }
        this.itemStatusChanged = item;
        this.openDenyModal(item);
        // this.denyPopupActive = true;
        return;
      }
      this.$axios
        .post("/guestlist/update-status", {
          event: this.url,
          status: status,
          id: item._id.$oid ? item._id.$oid : item._id
        })
        .then(response => {
          if (response.status == 200) {
            this.$toast(response.data.message, { type: "success" });
            this.$refs["current-guest-list"].fetchData();
            this.$refs["current-denied-list"].fetchData();
            this.$refs["wait-guest-list"].fetchData();
            this.getDeniedList();
            this.getWaitingList();
            this.getCurrentList();
            this.getListStats();
          }
        });
    },
    changeSwitch(val) {
      this.tableData1.showExpected = !this.tableData1.showExpected;
    },
    async getListStats() {
      this.$axios
        .post("/guestlist/get-stats", {
          event: this.url
        })
        .then(response => {
          const data = response.data;
          let totalExp = 0;
          let totalCheckIn = 0;
          let diff = 0;
          data.forEach(element => {
            totalExp += element.exp_guests;
            totalCheckIn += element.total_checkin;
          });
          this.totalExp = totalExp;
          this.totalCheckIn = totalCheckIn;
        });
    },
    async getCurrentList() {
      await this.$axios
        .get(`/guestlist/get-guests/${this.url}`)
        .then(response => {
          let data = response.data.data.items;
          this.data = JSON.parse(JSON.stringify(data));
          this.copyData = JSON.parse(JSON.stringify(data));
        });
    },
    async getDeniedList() {
      await this.$axios
        .get(`/guestlist/get-guests/${this.url}?type=denied`)
        .then(response => {
          let data = response.data.data.items;
          this.deniedData = JSON.parse(JSON.stringify(data));
          this.copyData = JSON.parse(JSON.stringify(data));
        });
    },
    async getWaitingList() {
      await this.$axios
        .get(`/guestlist/get-guests/${this.url}?type=waiting`)
        .then(response => {
          let data = response.data.data.items;
          this.waitingData = JSON.parse(JSON.stringify(data));
          this.copyData = JSON.parse(JSON.stringify(data));
        });
    },
    async getMobCurrentList(page, type) {
      await this.$axios
        .get(`/guestlist/get-guests/${this.url}?page=${page}&type=${type}`)
        .then(response => {
          let data = response.data.data.items;

          if (data.length !== 0) {
            data.forEach(element => {
              this.data.push(element);
            });
            this.refs.mobileTable.incrementPage();
          }
          // this.data = JSON.parse(JSON.stringify(data));
          // this.copyData = JSON.parse(JSON.stringify(data));
        });
    },
    onResize() {
      if (window.innerWidth < 650) {
        this.mobile = true;
      } else if (window.innerWidth < 900) {
        this.tableData1.columns = [
          {
            text: "Full Name",
            value: "name",

            row2: true,

            center: true,
            centerTitle: true
          },

          {
            text: "Male",
            value: "male_person_checkin",

            spinner: true,
            centerTitle: true,
            prefixIcon: "mars",
            male: true,
            row2: true
          },
          {
            text: "Female",
            value: "female_person_checkin",

            spinner: true,
            centerTitle: true,
            row2: true,
            prefixIcon: "venus",
            female: true
          },
          {
            text: "NS",
            value: "non_specific_person_checkin",

            spinner: true,
            centerTitle: true,
            prefixIcon: "genderless",
            ns: true,
            row2: true
          },
          {
            text: "Total",
            value: "total_checkin",

            guesttotal: true,
            centerTitle: true,
            row2: true
          },
          {
            text: "Details",
            value: "edit",

            modal: true,
            modalTitle: "Edit",
            row2: true,
            center: true,
            centerTitle: true
          },
          {
            text: "Approval",
            value: "status",
            options: [
              { label: "Approved", value: "approved" },
              { label: "Denied", value: "denied" },
              { label: "Pending", value: "pending" }
            ]
          }
          // {
          //   text: "",
          //   value: "change"
          // }
        ];
      } else {
        this.mobile = false;
        this.tableData1.columns = [
          {
            text: "Full Name",
            value: "name",

            row2: true,

            center: true,
            centerTitle: true
          },

          {
            text: "Male",
            value: "male_person_checkin",

            spinner: true,
            centerTitle: true,
            prefixIcon: "mars",
            male: true,
            row2: true
          },
          {
            text: "Female",
            value: "female_person_checkin",

            spinner: true,
            centerTitle: true,
            row2: true,
            prefixIcon: "venus",
            female: true
          },
          {
            text: "NS",
            value: "non_specific_person_checkin",

            spinner: true,
            centerTitle: true,
            prefixIcon: "genderless",
            ns: true,
            row2: true
          },
          {
            text: "Total",
            value: "total_checkin",

            guesttotal: true,
            centerTitle: true,
            row2: true
          },
          {
            text: "Details",
            value: "edit",

            modal: true,
            modalTitle: "Edit",
            row2: true,
            center: true,
            centerTitle: true
          },
          {
            text: "Approval",
            value: "status",
            options: [
              { label: "Approved", value: "approved" },
              { label: "Denied", value: "denied" },
              { label: "Pending", value: "pending" }
            ]
          },
          {
            text: "",
            value: "change"
          }
          // { title: "Expected Male", field: "male_person", hidden: true },
          // {
          //   title: "Expected Female",
          //   field: "female_person",
          //   hidden: true
          // },
          // {
          //   title: "Expected NS",
          //   field: "non_specific_person",
          //   hidden: true
          // },
          // {
          //   title: "Additional Notes",
          //   field: "additional_data",
          //   hidden: true
          // },

          // { title: "Submitted By", field: "submitted_by", hidden: true },
          // { title: "Phone", field: "phone", hidden: true },
          // { title: "Email", field: "email", hidden: true },

          // {
          //   title: "",
          //   field: "checkedIn",
          //   hidden: true
          // },
          // {
          //   title: "",
          //   field: "status",
          //   hidden: true
          // },

          // {
          //   title: "Approval",
          //   field: "status",
          //   width: "12%",
          //   centerTitle: true,
          //   center: true,
          //   row2: true,
          //   dropdown: true,
          //   status: true,

          //   options: [
          //     { label: "Approved", value: "approved" },
          //     { label: "Denied", value: "denied" },
          //     { label: "Pending", value: "pending" }
          //   ]
          // },
          // {
          //   title: "",
          //   field: "change",
          //   width: "5%",
          //   centerTitle: true,
          //   row2: true,
          //   checkBtn: true
          //   // guestlistBtn: true
          // }
        ];
      }
    },
    saveConfirmDenied(list) {
      var foundIndex = this.deniedData.findIndex(x => x._id == list._id);

      this.tempList = this.deniedData[foundIndex];
      this.$refs.alert.open({
        title: "Confirm Changes",
        msg: "Do you want to save your changes?",
        confirm: "Confirm",
        closeable: true,
        onConfirm: () => {
          this.saveInlineChangesDenied();
        }
      });
    },
    saveConfirm(list) {
      var foundIndex = this.data.findIndex(x => x._id == list._id);

      this.tempList = this.data[foundIndex];
      this.$refs.alert.open({
        title: "Confirm Changes",
        msg: "Do you want to save your changes?",
        confirm: "Confirm",
        closeable: true,
        onConfirm: () => {
          this.saveInlineChanges();
        }
      });
    },
    saveInlineChangesDenied() {
      let list = this.tempList;

      if (list.status === "pending" || list.status === "submitted") {
        this.changeGuest(list);
      } else {
        this.changeGuest(list, true);
        var foundIndex = this.deniedData.findIndex(x => x._id == list._id);

        if (list.status === "approved") {
          this.deniedData[foundIndex]["status"] = "approved";
          // this.updateStatusVal("approved", list._id);
        } else if (list.status === "denied") {
          this.deniedData[foundIndex]["deny_reason"] = list.deny_reason;
          this.deniedData[foundIndex]["status"] = "denied";
          // this.updateStatusVal("denied", list._id);
        }
      }
    },
    saveInlineChanges() {
      let list = this.tempList;

      if (list.status === "pending" || list.status === "submitted") {
        this.changeGuest(list);
      } else {
        this.changeGuest(list, true);
        var foundIndex = this.data.findIndex(x => x._id == list._id);

        if (list.status === "approved") {
          // this.updateStatusVal("approved", list._id);
        } else if (list.status === "denied") {
          this.deniedData.push(this.data[foundIndex]);
          if (foundIndex > -1) {
            this.data.splice(foundIndex, 1);
          }
          // this.updateStatusVal("denied", list._id);
        }
      }

      //finally change the list so we dont have to refetch

      this.tempList = "";
    },
    changeExpected(val) {
      this.expectedGuestlist = val;
    },
    async getGuestSettings() {
      await this.$axios
        .post("/guestlist/get-guestlist-settings", {
          event: this.url
        })
        .then(response => {
          const data = response.data;

          if (data.enable) {
            this.guestlistSettings = data;
          }
        });
    },
    // details(item) {
    //   console.log(item);
    //   // this.detailsIndex = this.data.indexOf(item);
    //   this.detailsItem = Object.assign({}, item);
    //   this.$refs.detailsModal.open(item);
    // },
    editGuest(guest, index) {
      // let modalGuest = guest;
      // this.guestPopup = modalGuest;
      // this.guestPopupActive = true;

      this.guestPopup = Object.assign({}, guest);
      this.$refs.detailsModal.open(guest);
    },
    editGuestTag(guest) {
      this.guestPopup = Object.assign({}, guest);
      this.$refs.detailsModal.open(guest);
      setTimeout(() => {
        this.$refs.detailsModal.tab = "tab-TAGS";
      }, 500);
    },
    changeGuest(guest, withStatus = false) {
      let guestObj = {};
      if (withStatus) {
        guestObj = {
          name: guest.name,
          estArrTime: guest.est_arr_time,
          section: guest.section,
          status: guest.status,
          denyReason: guest.deny_reason
        };
      } else {
        var foundIndex = this.data.findIndex(x => x._id == guest._id);

        this.data[foundIndex].status = "pending";
        guestObj = {
          name: guest.name,
          phone: guest.phone,
          estArrTime: guest.est_arr_time,
          section: guest.section,
          malePerson: guest.male_person,
          femalePerson: guest.female_person,
          nonSpecificPerson: guest.non_specific_person,
          changesNotes: guest.changes_notes
        };
      }
      this.$axios
        .post("/guestlist/edit-guest", {
          guest: guestObj,
          event: this.url,
          id: guest._id,
          editLink: `${this.embedLink}`,
          status: withStatus
        })
        .then(({ data }) => {
          this.guestPopupActive = false;
          this.guestPopup = {};

          if (!data.error) {
            this.$store.state.bus.$emit("alert", ModalContent.guestEdited);

            this.$loader.stop();
          } else {
            this.$store.state.bus.$emit("alert", {
              title: "Error",
              msg: data.error.message
            });
            this.$loader.stop();
          }
        });
    },
    denyGuest(reason) {
      if (reason) {
        this.$axios
          .post("/guestlist/update-status", {
            event: this.url,
            status: "denied",
            id: this.itemStatusChanged._id,
            reason: reason
          })
          .then(response => {
            if (response.status == 200) {
              this.ignoreStatusChange = true;
              this.itemStatusChanged.status = 'denied';
              this.$toast(response.data.message, { type: "success" });
              this.$refs["current-guest-list"].fetchData();
              this.$refs["current-denied-list"].fetchData();
              this.$refs["wait-guest-list"].fetchData();
              this.getDeniedList();
              this.getWaitingList();
              this.getCurrentList();
              this.getListStats();
            }
          });
        this.denyPopupActive = false;
        this.denyModal = {};
      }
    },
    closeDenyPopup() {
      this.denyPopupActive = false;
      this.denyModal = {};
      this.itemStatusChanged.status = this.itemStatusChanged.old_status;
    },
    closeGuestPopup() {
      this.guestPopupActive = false;
      this.guestPopup = {};
    },

    updateGuestlist(type, item, fieldType) {
      // if (list[field] === "") {
      //   return;
      // }

      // else if (type === "approve") {
      //   this.updateStatus(list, field, "approve");
      // } else if (type === "deny") {
      //   this.denyPopupActive = true;
      //   this.denyModal = {
      //     list: list,
      //     field: field,
      //     type: type
      //   };
      // }

      if (type === "minus") {
        this.minus(list, item);
      } else if (type === "plus") {
        this.plus(list, item);
      }
      // } else if (field === "status") {
      //   if (list.status === "approved") {
      //     this.updateStatus(list, field, "approve");
      //   } else if (list.status === "denied") {
      //     this.denyPopupActive = true;
      //     this.denyModal = {
      //       list: list,
      //       field: field,
      //       type: type
      //     };
      //   }
      // } else {
      //   var foundIndex = this.copyData.findIndex(x => x._id == list._id);

      //   this.copyData[foundIndex][field] = list[field];
      //   // this.$axios.post("/guestlist/update-guest", {
      //   //   event: this.url,
      //   //   guest: {
      //   //     id: list._id,
      //   //     field,
      //   //     value: list[field]
      //   //   }
      //   // });
      // }
    },
    minus(type, list) {
      if (list[type] > 0) {
        list[type]--;
        list["total_checkin"]--;
      }

      this.updateCheckIns("minus", list._id, type);
    },
    plus(type, list) {
      list[type]++;
      list["total_checkin"]++;
      this.updateCheckIns("plus", list._id, type);
    },
    updateStatusVal(val, id, reason) {
      this.$loader.start();
      this.$axios
        .post("/guestlist/update-status", {
          event: this.url,
          guestlistId: id,
          status: val,
          reason: reason || ""
        })
        .then(({ data }) => {
          this.denyPopupActive = false;
          this.denyModal = {};

          if (!data.error) {
            this.$store.state.bus.$emit("alert", ModalContent.guestEdited);

            if (val == "denied") {
              var foundIndex = this.data.findIndex(x => x._id == id);
              this.data[foundIndex].status = "denied";
              this.deniedData.push(this.data[foundIndex]);
              if (foundIndex > -1) {
                this.data.splice(foundIndex, 1);
              }
            }
          } else {
            this.$store.state.bus.$emit("alert", {
              title: "Error",
              msg: data.error.message
            });
          }
          this.$loader.stop();
        });
    },
    updateCheckIns(val, id, field, index) {
      this.$axios
        .post("/guestlist/update-checkin", {
          event: this.url,
          guestlistId: id,
          type: val,
          field: field
        })
        .then(({ data }) => {
          this.getListStats();

          if (data.checkedIn) {
            if (index) {
              this.copyData[index]["checkedIn"] = true;
            }
          }
          // if (!data.error && data.checkIn) {
          //   this.$store.state.bus.$emit("alert", ModalContent.guestEdited);
          // } else {
          //   this.$store.state.bus.$emit("alert", {
          //     title: "Error",
          //     msg: data.error.message
          //   });
          // }
        });
    },
    updateStatus(list, field, type, denyMessage = "") {
      var foundIndex = this.data.findIndex(x => x._id == list._id);

      if (type === "approve") {
        this.data[foundIndex]["status"] = "approved";
        // this.updateStatusVal("approved", list._id);
      } else {
        this.data[foundIndex]["deny_reason"] = denyMessage;
        this.data[foundIndex]["status"] = "denied";
        // this.updateStatusVal("denied", list._id);
      }
    },

    mapCSVData(data) {
      const lengths = data.map(a =>
        a.guestlist_items ? a.guestlist_items.length : 0
      );
      let maxLength = Math.max(...lengths);

      data.forEach(guestlist => {
        for (let index = 1; index <= maxLength; index++) {
          guestlist[`guestlist_purchase-${index}`] = "";
        }
      });
      data.forEach(guestlist => {
        if (guestlist.guestlist_items) {
          guestlist.guestlist_items.forEach((item, index) => {
            guestlist[
              `guestlist_purchase-${index + 1}`
            ] = `${item.name} - Qty ${item.quantity}`;
          });
        }
      });

      return data;
    }
  }
};
</script>
<style lang="less" scoped>
.name-tag {
  display: flex;
  align-items: center;
  justify-content: start;

  .red-dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    background: red;
    border-radius: 50%;
    margin-right: 6px;
  }
}

.v-icon.v-icon {
  height: fit-content;
}
.item-icon.active {
  color: var(--primary);
}
.est-people-container {
  display: flex;

  .minus,
  .plus {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--input-background);
    cursor: pointer;
    font-family: inherit;
    font-size: 1.5em;
    color: var(--text);
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    transition: all 0.4s ease, visibility 0s;
    -webkit-user-select: none;
    user-select: none;
  }

  label {
    margin-bottom: 10px;
  }

  .input-container {
    display: flex;
  }

  .counter {
    display: flex;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input {
    height: 34px;
    width: 100px;
    padding: 4px 0;

    text-align: center;
    box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
    border: 1px solid transparent;
    background: var(--input-background);
    color: var(--text);
    font-size: 26px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    appearance: none;
    outline: none;

    &:hover {
      border: 1px solid var(--input-border);
    }

    &:focus {
      color: var(--text);
      border: 1px solid #48bb8b;
      background-color: var(--active-background);
    }
  }

  .gender-icon {
    font-size: 30px;
    text-align: center;
    margin: auto;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 10px;
    color: white;

    &.male {
      background-color: #2da9d8;
    }

    &.female {
      background-color: #b73477;
    }

    &.ns {
      background: linear-gradient(
        180deg,
        #fe0000 16.66%,
        #fd8c00 16.66%,
        33.32%,
        #ffe500 33.32%,
        49.98%,
        #119f0b 49.98%,
        66.64%,
        #0644b3 66.64%,
        83.3%,
        #c22edc 83.3%
      );
    }
  }
}

&::v-deep .data-table .content .v-data-table .v-data-table__wrapper {
  overflow: visible !important;

  @media screen and (max-width: 650px) {
    > table > tbody > tr > td:not(:last-child) {
      border-bottom: 0px !important;
    }
  }
}

span.spinner-input {
  width: 100%;

  div.counter {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .minus,
    .plus {
      font-size: 13px;
      background: var(--input-background);
      color: var(--text);
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      border-radius: 14px;
      // border: 1px solid var(--input-border);
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield; /* Firefox */
    }

    input {
      width: 45px;
      padding: 3px 0;
      text-align: center;
      box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
      border: 1px solid var(--input-border);
      background: var(--input-background);
      color: var(--text);
      border-radius: 8px;
      display: inline-block;
      vertical-align: middle;
      -webkit-appearance: none;
      appearance: none;
      outline: none;

      &:hover {
        border: 1px solid var(--input-border);
      }

      &:focus {
        color: var(--text);
        border: 1px solid #48bb8b;
        background-color: var(--active-background);
      }
    }
  }
}

span.guest-total {
  width: 60px;
  display: block;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border-radius: 20px;
  color: white;

  &.checkin {
    background: #449a4c;
  }

  &.same {
    background: #2bacd9;
  }

  &.over {
    background: red;
  }
}

td.tags {
  display: flex;
  max-width: 50%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  align-items: center;
  column-gap: 8px;
  overflow: scroll;
  .v-chip {
    min-width: fit-content;
  }
}

.input-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5em;
  position: relative;
  width: 100%;
}

::v-deep .formulate-input-wrapper {
  .dropdown-outer {
    min-width: 100px;
    .dropdown {
      padding: 4px 12px;
      // margin-bottom: 0;
    }
    // .options-inner option {
    //   padding: 4px 12px;
    // }

    .icon-down {
      right: 14px;
      top: initial;
    }
  }
}
</style>
