<template>
  <transition
    name="modal"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <font-awesome-icon
            icon="times"
            id="remove-pop-up"
            @click="closePopUp"
          />
          <div class="modal-header">
            <h2 class="modal-header-title modal-header-info">
              {{ guest.name }}
            </h2>
            <h3 class="modal-header-text modal-header-info">
              <!-- {{ "Tier Type: " + tier.type }} -->
            </h3>
          </div>

          <div class="modal-body" id="modal-scroll-container">
            <div class="modal-body-info modal-body-content" id="Content">
              <h3 class="modal-body-title">Guest Info</h3>
              <p class="modal-body-subtitle">
                <b>NOTE:</b> Changes made will be sent to customer.
              </p>
              <div class="modal-body-info-container content-inner">
                <div class="row" v-if="mobile">
                  <div class="col full">
                    <diyobo-input
                      type="dropdown"
                      label="Status"
                      placeholder="Status"
                      v-model="guest.status"
                      :options="statusOptions"
                      :val="guest.status"
                    />
                    <div class="col full" v-if="guest.status === 'denied'">
                      <diyobo-input
                        class="notes"
                        type="textbox"
                        label="Deny Reason"
                        :placeholder="'Please add something here'"
                        v-model="guest.deny_reason"
                        :val="guest.deny_reason"
                      />
                    </div>
                    <div class="col full">
                      <diyobo-button
                        class="edit"
                        :type="'secondary'"
                        :txt="'Update'"
                        @click="updateStatus"
                      />
                    </div>
                  </div>
                  <hr class="divider" />
                </div>

                <div class="row">
                  <div class="col half">
                    <diyobo-input
                      type="text"
                      label="Full Name"
                      placeholder="Full Name"
                      required
                      v-model="guest.name"
                      :val="guest.name"
                    />
                  </div>
                  <div class="col half">
                    <diyobo-input
                      id="pv-email"
                      type="email"
                      label="Email"
                      placeholder="example@domain.com"
                      required
                      :val="guest.email"
                      readOnly
                    />
                  </div>
                  <!-- v-on:blur="onBlurEmailInput"
            @keyup="validateAt('email')" -->
                  <div class="col half">
                    <diyobo-input
                      label="Phone Number"
                      id="phone-input"
                      type="text"
                      placeholder="(204) 222-2222"
                      :required="false"
                      :val="guest.phone"
                      :max="16"
                    />
                  </div>

                  <div class="col half">
                    <diyobo-input
                      type="dropdown"
                      label="Estimate Arrival Time"
                      placeholder="Select Your Arrival Time"
                      required
                      v-model="guest.est_arr_time"
                      :options="settings.time_slots"
                      :val="guest.est_arr_time"
                    />
                  </div>

                  <div class="col half">
                    <diyobo-input
                      type="text"
                      label="Occasion"
                      placeholder="Reason For Booking?"
                      required
                      :val="guest.occasion"
                      readOnly
                    />
                  </div>

                  <div class="col half">
                    <diyobo-input
                      type="dropdown"
                      label="Section/Table"
                      placeholder="Please Select"
                      v-model="guest.section"
                      :options="settings.sections"
                      :val="guest.section"
                    />
                  </div>

                  <div class="col full">
                    <div class="est-people-container input-wrapper">
                      <label for="est-people">Est# People </label>
                      <div class="input-container">
                        <div class="male-counter">
                          <div class="gender-icon male">
                            <font-awesome-icon
                              :icon="['fas', 'mars']"
                              class="male-icon"
                            />
                          </div>
                          <div class="counter">
                            <span class="minus" @click="minus('male')">-</span>
                            <input
                              type="number"
                              name=""
                              id=""
                              class="male-input"
                              min="1"
                              v-model="guest.male_person"
                            />
                            <span class="plus" @click="plus('male')">+</span>
                          </div>
                        </div>
                        <div class="female-counter">
                          <div class="gender-icon female">
                            <font-awesome-icon
                              :icon="['fas', 'venus']"
                              class="female-icon"
                            />
                          </div>
                          <div class="counter">
                            <span class="minus" @click="minus('female')"
                              >-</span
                            >
                            <input
                              type="number"
                              name=""
                              id=""
                              class="female-input"
                              min="1"
                              v-model="guest.female_person"
                            />
                            <span class="plus" @click="plus('female')">+</span>
                          </div>
                        </div>
                        <div class="non-specific-counter">
                          <div class="gender-icon ns">
                            <font-awesome-icon
                              :icon="['fas', 'genderless']"
                              class="others-icon"
                            />
                          </div>
                          <div class="counter">
                            <span class="minus" @click="minus('ns')">-</span>
                            <input
                              type="number"
                              name=""
                              id=""
                              class="non-specific-input"
                              min="1"
                              v-model="guest.non_specific_person"
                            />
                            <span class="plus" @click="plus('ns')">+</span>
                          </div>
                        </div>
                        <div class="total-counter">
                          <div class="label">Total Guests</div>
                          <div class="total-container">
                            {{ totalGuests }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col full">
                    <diyobo-input
                      class="notes"
                      type="textbox"
                      label="Booking Notes"
                      :placeholder="'Please add something here'"
                      v-model="guest.additional_data"
                      :val="guest.additional_data"
                      readOnly
                    />
                  </div>

                  <div class="col full">
                    <diyobo-input
                      class="notes"
                      type="textbox"
                      label="Note to Guest"
                      :placeholder="'Please add something here'"
                      v-model="guest.changes_notes"
                      :val="guest.changes_notes"
                    />
                  </div>

                  <div class="col full">
                    <diyobo-input
                      v-if="!mobile && guest.deny_reason"
                      class="notes"
                      type="textbox"
                      label="Deny Reason"
                      :placeholder="'Please add something here'"
                      readOnly
                      :val="guest.deny_reason"
                    />
                  </div>

                  <div v-if="guest.submitted_by" class="row">
                    <div class="col full">
                      Submitted By {{ guest.submitted_by }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-header bottom">
            <div class="modal-btn-ctn">
              <diyobo-button
                class="close"
                :type="'primary'"
                :txt="'Back'"
                @click="closePopUp"
              />
              <diyobo-button
                class="edit"
                :type="'secondary'"
                :txt="'Save'"
                :disabled="!madeChanges"
                @click="saveGuest"
              />
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="less">
// #Content.modal-body-info {
//   min-height: initial;
//   z-index: 15;
.modal-body-info-container {
  padding: 0 !important;
}
// }
</style>
<style lang="less" scoped>
::-webkit-scrollbar {
  width: 3px;
  color: #000000;
}
::-webkit-scrollbar-track {
  display: none;
}
.modal-mask {
  //position: absolute;
  position: fixed;
  /* margin: auto; */
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: 0.4s;

  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #3333339d;

    .modal-container {
      margin: auto;
      padding: 2px;
      position: relative;
      width: 96%;
      //skinny
      //max-width: 530px;
      //fat
      max-width: 700px !important;

      background-color: #4a639e;
      background-image: linear-gradient(160deg, #4eb95e, #4a639e);
      box-shadow: 1px 1px 8px 2px #111111c2;
      border-radius: 8px;
      transition: top 0.5s ease;

      #remove-pop-up {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }

      .modal-header,
      .modal-body {
        width: calc(100% - 40px);
        min-height: 50px;
      }

      .modal-header {
        //display: flex;
        //align-items: center;
        //justify-content: space-between;
        display: block;
        text-align: center;
        padding: 2px 20px;
        border-radius: 7px 7px 0 0;
        background-color: var(--content-background);

        .modal-header-title {
          width: 100%;
          margin: 0;
          margin-top: 10px;
          height: fit-content;
        }
        .modal-header-text {
          width: 100%;
          margin: 0;
          margin-bottom: 15px;
          height: fit-content;
        }
      }
      // end modal-header
      .bottom {
        border-radius: 0px 0px 7px 7px;
      }
      .modal-body {
        padding: 0em 20px;
        padding-top: 1em;
        padding-bottom: 1em;
        border-top: 1px solid var(--input-border);
        border-bottom: 1px solid var(--input-border);

        //border-radius: 0 0 7px 7px;
        background-color: var(--content-background);
        // box-shadow: inset 0 0 3px 0px #11111191;
        max-height: 560px;
        overflow: auto;

        .modal-body-title {
          margin: 0px;
        }
        .modal-body-subtitle {
          margin: 0;
          margin-bottom: 1.5em;
          text-align: center;
        }

        .modal-body-content {
          margin: 7.5px 0px;
        }

        h3,
        h4,
        h2 {
          margin: 15px 0px;
          text-align: center;
        }

        .modal-body-container .error-label {
          margin-bottom: 0.75em;
          color: #e60022;
          text-align: center;
          font-weight: 600;
        }

        .basic-info {
          display: flex;
          justify-content: space-evenly;
          p {
            font-size: 1em;
          }
        }

        .basic-description {
          p {
            text-align: center;
          }
        }

        .venue-info {
          text-align: center;
          .venue-date-text {
            width: 45%;
            display: inline-block;
          }
          .venue-date-info {
            display: flex;
            justify-content: space-evenly;
          }
        }

        .table {
          width: 100%;
          text-align: center;
          margin: 18px 0px;
        }
        // end button-wrapper
      }
      // end modal-body
      .modal-btn-ctn {
        display: flex;
        justify-content: space-evenly;
        padding: 15px 0px;
        width: 100%;
      }
      p {
        font-size: 1em !important;
      }
    }
    // end modal-dialog
  }
  // end modal-inner
}
#Content > .content-inner .modal-body-info-container {
  hr.divider {
    margin: 2em auto;
  }
}
.est-people-container {
  // display: flex;

  .minus,
  .plus {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--input-background);
    cursor: pointer;
    font-family: inherit;
    font-size: 1.5em;
    color: var(--text);
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
    outline: none;
    -webkit-appearance: none;
    transition: all 0.4s ease, visibility 0s;
    -webkit-user-select: none;
  }
  label {
  }
  .input-container {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    align-items: center;
  }

  .male-counter,
  .female-counter,
  .non-specific-counter {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .counter {
    display: flex;
  }

  @media screen and (max-width: 555px) {
    .input-container {
      justify-content: center;
    }
    .male-counter,
    .female-counter,
    .non-specific-counter {
      margin: 10px 0;
    }

    .total-counter {
      margin-left: 40px;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  input {
    height: 34px;
    width: 100px;
    padding: 4px 0;

    text-align: center;
    box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
    border: 1px solid transparent;
    background: var(--input-background);
    color: var(--text);
    font-size: 26px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none;

    &:hover {
      border: 1px solid var(--input-border);
    }
    &:focus {
      color: var(--text);
      border: 1px solid #48bb8b;
      background-color: var(--active-background);
    }
  }

  .gender-icon {
    font-size: 30px;
    text-align: center;
    margin: auto;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
    color: white;
    &.male {
      background-color: #2da9d8;
    }
    &.female {
      background-color: #b73477;
    }
    &.ns {
      font-size: 40px;
      --g-red: #d04b36;
      --g-orange: #e36511;
      --g-yellow: #ffba00;
      --g-green: #00b180;
      --g-blue: #147aab;
      --g-indigo: #675997;
      background-image: linear-gradient(
        var(--g-red) 0%,
        var(--g-red) 16.6666%,
        var(--g-orange) 16.6666%,
        var(--g-orange) 33.333%,
        var(--g-yellow) 33.333%,
        var(--g-yellow) 50%,
        var(--g-green) 50%,
        var(--g-green) 66.6666%,
        var(--g-blue) 66.6666%,
        var(--g-blue) 83.3333%,
        var(--g-indigo) 83.3333%,
        var(--g-indigo) 100%
      );

      // background: linear-gradient(
      //   180deg,
      //   #fe0000 16.66%,
      //   #fd8c00 16.66%,
      //   33.32%,
      //   #ffe500 33.32%,
      //   49.98%,
      //   #119f0b 49.98%,
      //   66.64%,
      //   #0644b3 66.64%,
      //   83.3%,
      //   #c22edc 83.3%
      // );
    }
  }

  .total-counter {
    width: 150px;
    padding: 4px 0;

    text-align: center;
    box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
    border: 1px solid transparent;
    background: var(--input-background);
    color: var(--text);
    font-size: 26px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none;

    .label {
      font-size: 12px;
    }
    .total-container {
      font-size: 20px;
    }
  }
}
.input-wrapper {
  // display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5em;
  position: relative;
  width: 100%;
}
</style>

<script>
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTimes,
  faTransgender,
  faMars,
  faVenus,
  faGenderless
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import DiyoboInput from "@/components/DiyoboInput.vue";

library.add(faTimes, faTransgender, faMars, faVenus, faGenderless);

export default {
  components: {
    DiyoboButton,
    DiyoboInput,
    FontAwesomeIcon
  },
  props: {
    info: Object,
    settings: Object
  },
  data() {
    return {
      mobile: false,
      madeChanges: false,
      guest: this.info,
      statusOptions: [
        { label: "Approved", value: "approved" },
        { label: "Denied", value: "denied" }
      ]
      // ticketQuantity: 0,
    };
  },
  watch: {
    info: {
      handler: function (newVal) {
        this.madeChanges = true;
      },
      deep: true
    }
  },
  computed: {
    totalGuests() {
      let sum =
        parseInt(this.guest.male_person) +
        parseInt(this.guest.female_person) +
        parseInt(this.guest.non_specific_person);
      return sum;
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth < 650) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
    minus(type) {
      if (type === "male") {
        if (this.guest.male_person > 0) {
          this.guest.male_person--;
        }
      } else if (type === "female") {
        if (this.guest.female_person > 0) {
          this.guest.female_person--;
        }
      } else {
        if (this.guest.non_specific_person > 0) {
          this.guest.non_specific_person--;
        }
      }
    },
    plus(type) {
      if (type === "male") {
        this.guest.male_person++;
      } else if (type === "female") {
        this.guest.female_person++;
      } else {
        this.guest.non_specific_person++;
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      let targetElement = document.querySelector("#modal-scroll-container");
      disableBodyScroll(targetElement);
    },
    enter(el) {
      el.style.opacity = 1;
    },
    leave(el) {
      el.style.opacity = 0;
    },
    editTierPopup() {
      let targetElement = document.querySelector("#modal-scroll-container");
      clearAllBodyScrollLocks();
      this.$emit("edit-tier", this.tier);
    },
    closePopUp() {
      let targetElement = document.querySelector("#modal-scroll-container");
      clearAllBodyScrollLocks();
      this.$emit("close");
    },
    saveGuest() {
      clearAllBodyScrollLocks();
      this.$emit("save-guest", this.guest);
    },
    updateStatus() {
      this.$emit("save-guest", this.guest, true);
    },
    tierDate(val) {
      return dateFormat(new Date(val), "ddd, mmm dS, yyyy");
    },
    time(val) {
      let time;
      let timeArr = val.split(":");

      if (parseInt(timeArr[0]) >= 12) {
        if (parseInt(timeArr[0]) == 12) {
          time = parseInt(timeArr[0]) + ":" + timeArr[1] + " PM";
        } else {
          time = parseInt(timeArr[0] - 12) + ":" + timeArr[1] + " PM";
        }
      } else {
        if (parseInt(timeArr[0]) == 0) {
          timeArr[0] = 12;
        }

        time = timeArr[0] + ":" + timeArr[1] + " AM";
      }

      return time;
    },
    formatFullDate(dateString, timeString) {
      return dateFormat(
        new Date(dateString + " " + timeString),
        "mmm dd:HH:mm TT, yyyy"
      );
    },
    unixFullDate(val) {
      if (val.$date) {
        return dateFormat(
          new Date(+val.$date.$numberLong),
          "mmm dd:HH:mm TT, yyyy"
        );
      } else {
        return dateFormat(new Date(val), "mmm dd:HH:mm TT, yyyy");
      }
    },
    unixTierDate(val) {
      if (val.$date) {
        return dateFormat(
          new Date(+val.$date.$numberLong),
          "ddd, mmm dS, yyyy"
        );
      } else {
        return dateFormat(new Date(val), "ddd, mmm dS, yyyy");
      }
    },
    unixTierTime(val) {
      if (val.$date) {
        return dateFormat(new Date(+val.$date.$numberLong), "hh:MM TT");
      } else {
        return dateFormat(new Date(val), "hh:MM TT");
      }
    },
    showExcludedDate(val) {
      if (val.day) {
        let stringToUse = val.month + "/" + val.day + "/" + val.year;
        return dateFormat(stringToUse, "ddd, mmm dS, yyyy");
      }
    }
  },
  mounted() {
    if (window.innerWidth < 650) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    window.addEventListener("resize", this.onResize);
  },
  beforeMount() {
    let targetElement = document.querySelector("#modal-scroll-container");
    disableBodyScroll(targetElement);
  },
  beforeDestroy() {
    let targetElement = document.querySelector("#modal-scroll-container");
    enableBodyScroll(targetElement);
  }
};
</script>
