<template>
  <TTDialog :active="active" v-on:close="close" :width="650">
    <div id="Content">
      <div class="content-inner">
        <v-card class="rounded-8">
          <v-tabs fixed-tabs :show-arrows="true" v-model="tab" slider-size="4">
            <v-tabs-slider color="var(--primary)"></v-tabs-slider>
            <v-tab v-for="tab in tabs" :key="tab" :href="`#tab-${tab}`">
              {{ tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-DETAILS">
              <v-card-title>
                <span class="text-h5">Details</span>
              </v-card-title>

              <v-card-text>
                <div class="row">
                  <div class="detail col half">
                    <FormulateInput
                      label="First Name"
                      placeholder="Name"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.first_name"
                      autofill="false"
                      validation="required"
                    />
                  </div>
                  <div class="detail col half">
                    <FormulateInput
                      label="Last Name"
                      placeholder="Name"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.last_name"
                      autofill="false"
                      validation="required"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="detail col half">
                    <FormulateInput
                      label="Email"
                      placeholder="Email"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.email"
                      validation="required"
                    />
                  </div>
                  <div class="detail col half">
                    <FormulateInput
                      label="Phone Number"
                      placeholder="Phone"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.phone"
                      validation="required"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="detail col half">
                    <FormulateInput
                      label="Reservation Date"
                      placeholder="Reservation Date"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.reservation_date || 'N/A'"
                      validation="required"
                    />
                  </div>
                  <div class="detail col half">
                    <FormulateInput
                      label="Estimate time of Arrival"
                      placeholder="Email"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.est_arr_time"
                      validation="required"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="detail col half">
                    <FormulateInput
                      label="Occasion"
                      placeholder="Name"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.occasion"
                      validation="required"
                    />
                  </div>
                  <div class="detail col half">
                    <FormulateInput
                      label="Birthday"
                      placeholder="Email"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.birthdate"
                      validation="required"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="detail col half">
                    <FormulateInput
                      label="Promoter Referral"
                      placeholder="Promoter Name"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.pv"
                    />
                  </div>
                  <div class="detail col half">
                    <FormulateInput
                      label="Promo Code"
                      placeholder="Promo Code"
                      type="text"
                      disabled
                      name="value"
                      :value="''"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="detail col half">
                    <FormulateInput
                      label="Preferred Server"
                      placeholder="Preferred Server"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.server || ''"
                    />
                  </div>
                  <div class="detail col half">
                    <FormulateInput
                      label="Section Table"
                      placeholder="Section Table"
                      type="text"
                      disabled
                      name="value"
                      :value="guest.section"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="detail col full">
                    <FormulateInput
                      label="Booking Notes"
                      placeholder="Booking Notes"
                      type="textarea"
                      name="value"
                      :value="guest.additional_data"
                      validation="required"
                    />
                  </div>
                  <div class="detail col full">
                    <FormulateInput
                      label="Note to Guest"
                      placeholder="Note to Guest"
                      type="textarea"
                      name="value"
                      :value="guest.notes"
                      validation="required"
                    />
                  </div>
                </div>
                <!-- <div class="row">
              <div class="detail col half">
                <strong>Ticket Count</strong>

                <span>{{ guest.ticket_count }}</span>
              </div>
              <div class="detail col half">
                <strong>Product Count</strong>

                <span>{{ guest.product_count }}</span>
              </div>
            </div>
            <div class="row">
              <div class="detail col half">
                <strong>Invoice</strong>

                <span>{{ guest.invoice }}</span>
              </div>
              <div class="detail col half">
                <strong>IP Address</strong>

                <span>
                  {{
                    order.buyer_ip_information
                      ? order.buyer_ip_information.ip
                      : "N/A"
                  }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="detail col half">
                <strong>City</strong>

                <span>{{ order.city }}</span>
              </div>
              <div class="detail col half">
                <strong>Subtotal</strong>

                <span>${{ order.ticket_subtotal }}</span>
              </div>
            </div> -->
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text></v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item value="tab-ORDER DETAILS">
              <v-card-title>
                <span class="text-h5">Order Details</span>
              </v-card-title>

              <v-simple-table v-if="guest.guestlist_items">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 50%; text-align: left;"
                      >
                        Item
                      </th>
                      <th class="text-left" style="text-align: left;">
                        Quantity
                      </th>
                      <th class="text-left" style="text-align: left;">
                        Sub-Total
                      </th>
                      <!-- <th class="text-left">Allocated</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in guest.guestlist_items" :key="item._id">
                      <td>
                        {{ item.name }}
                      </td>
                      <td>{{ item.quantity }}</td>
                      <td>${{ item.total }}</td>
                      <!-- <td>1</td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-card-text v-else>
                <div class="row">
                  <div class="col full"></div>
                  <h4>No Order Details</h4>
                </div>
              </v-card-text>

              <v-card-actions v-if="total > 0">
                <v-spacer></v-spacer>

                <h4>TOTAL:</h4>
                <h4>${{ total }}</h4>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item value="tab-TAGS" style="min-height: 520px;">
              <v-card-text>
                <p>Current Tags</p>
                <div class="row">
                  <div class="col full">
                    <div class="current-tags">
                      <v-chip
                        class="ma-2"
                        close
                        :color="tag.color"
                        text-color="white"
                        v-for="(tag, index) in tags"
                        :key="index"
                        @click:close="deleteTag(index)"
                      >
                        {{ tag.name }}
                      </v-chip>
                    </div>
                  </div>
                </div>
                <div class="divider"></div>
                <div class="row">
                  <div class="col half">
                    <label for="color">Select Tag Color</label>
                    <color-picker
                      name="color"
                      display="Tag Color"
                      :color="tagColor"
                      @change="applyColor(...arguments)"
                    />
                    <!-- <FormulateInput
                      label="Add a new tag"
                      placeholder="Tag Name"
                      type="colorPicker"
                      name="color"
                    /> -->
                  </div>
                  <div class="col half" @keypress.enter="onAddTag">
                    <FormulateInput
                      label="Add a new tag"
                      placeholder="Tag Name"
                      validation="required"
                      v-model="tag"
                      type="text"
                      :error="error"
                    />
                  </div>
                  <div class="col full">
                    <span>
                      Pick your color, input your tag and then hit Enter.
                    </span>
                  </div>
                </div>
                <div class="divider"></div>
                <p>Add a saved tag</p>
                <div class="row">
                  <div class="col full">
                    <div class="current-tags">
                      <v-chip
                        class="ma-2"
                        :color="tag.color"
                        text-color="white"
                        v-for="(tag, index) in allTags"
                        :key="index"
                        :close="false"
                        @click="applyTag(index)"
                      >
                        {{ tag.name }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text></v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </div>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";
import ColorPicker from "@/components/ColorPicker.vue";

export default {
  name: "order-details",
  components: {
    TTDialog,
    ColorPicker
  },
  props: {
    isReservedSeatsType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false,
      guest: null,
      tab: 0,
      tabs: ["DETAILS", "ORDER DETAILS", "TAGS"],
      tags: [],
      tag: "",
      tagColor: "#000000",
      error: null,
      allTags: []
    };
  },
  computed: {
    total() {
      if (this.guest.guestlist_items && this.guest.guestlist_items.length > 0) {
        let total = 0;
        this.guest.guestlist_items.forEach(element => {
          total += element.total;
        });
        return total;
      }
      return 0;
    }
  },
  methods: {
    applyTag(index) {
      this.$axios
        .post("/guestlist/tags/add", {
          guestlist_id: this.guest._id,
          event_url: this.$route.params.url,
          tag: this.allTags[index]._id
        })
        .then(response => {
          if (response.status == 200) {
            if (this.allTags[index].tag_id === undefined) {
              this.allTags[index].tag_id = {'$oid' : this.allTags[index]._id};
            }
            this.tags.push(this.allTags[index]);
            this.allTags.splice(index, 1);
          }
        });
    },
    deleteTag(index) {
      this.$axios
        .post("/guestlist/tags/delete", {
          guestlist_id: this.guest._id,
          event_url: this.$route.params.url,
          tag: this.tags[index].tag_id.$oid
        })
        .then(response => {
          if (response.status == 200) {
            this.tags[index]._id = this.tags[index].tag_id.$oid
            this.allTags.push(this.tags[index]);
            this.tags.splice(index, 1);
          }
        });
    },
    applyColor(color) {
      this.tagColor = color;
    },
    onAddTag() {
      this.error = null;
      const tag = this.tag.trim();
      if (!tag) {
        return;
      }
      this.$axios
        .post("/guestlist/tags/create", {
          guestlist_id: this.guest._id,
          event_url: this.$route.params.url,
          tag: tag,
          tagColor: this.tagColor
        })
        .then(response => {
          if (response.data.error) {
            this.error = response.data.error;
          } else {
            this.tags.push(response.data.tag);
          }
        });
      this.tag = "";
      this.$forceUpdate();
    },
    open(guest) {
      this.guest = guest;
      this.tags = guest.tags || [];
      this.$axios
        .post("/guestlist/tags/all", { _id: guest._id })
        .then(response => {
          this.allTags = response.data;
        });
      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    hasValueNested(obj, key) {
      return key.split(".").every(function(x) {
        if (typeof obj != "object" || obj === null || !x in obj) return false;
        obj = obj[x];
        return true;
      });
    }
  }
};
</script>


<style>
.v-application--wrap {
  min-height: fit-content !important;
}
</style>
<style lang="less" scoped>
#Content {
  min-height: initial;
}
#Content .content-inner {
  width: 100%;
  padding: 0;
}
.v-application a {
  font-weight: bold;
  color: #ffffff;
}
.v-window {
  overflow: initial;
}
.v-chip {
  margin: 4px;
}
.detail {
  display: flex;
  flex-direction: column;
}
</style>